import React, { useState, useEffect, useCallback } from 'react'
import CommonAddComponent from '../../../../components/common/CommonAddComponent'
import BusinessIcon from '@material-ui/icons/Business';
import { Grid, Typography, Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions'
import { tablestyle, getComparator, stableSort, getSelectedDeviceType } from '../../../../utils'
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { withTranslation } from "react-i18next";
import AddIcon from '@material-ui/icons/Add';
import _ from "lodash"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import {
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Switch,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import EnhancedTableHead from '../../../../components/common/EnhancedTableHead';
import TableLoader from '../../../../components/common/TableLoader';
import CustomTablePagination from '../../../../components/common/CustomPagination';
import * as commonActions from '../../../common/actions'
import SearchField from '../../../../components/common/SearchField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CustomDrawer from '../../../../components/common/CustomDrawer';
import CustomAccordion from '../../../../components/common/CustomAccordion';
import CustomSearchBar from '../../../../components/common/CustomSearchBar';
import SearchFilterButtons from '../../../../components/common/SearchFilterButtons';

const manageHead = [
    { id: 'Serial Number', sortByKey: 'serial_number', label: "Serial Number", className: "show-pointer" },
    { id: 'firmware_version', sortByKey: 'firmware_version', label: "Firmware Version", className: "show-pointer" },
    { id: 'available_firmwar', sortByKey: 'available_firmwar', label: "Target Version" },
    { id: 'upgradable', sortByKey: 'upgradable', label: "Update Flag" },
];

const manageSafelinkHead = [
    { id: 'Serial Number', sortByKey: 'serial_number', label: "MAC Address", className: "show-pointer" },
    { id: 'firmware_version', sortByKey: 'firmware_version', label: "Firmware Version", className: "show-pointer" },
    { id: 'available_firmwar', sortByKey: 'available_firmwar', label: "Target Version" },
    { id: 'upgradable', sortByKey: 'upgradable', label: "Update Flag" },
];

const initialAccordion = {
    serialNumberAcc: false,
}

const initialFilter = {
    isOpen: false,
    serial_number: '',
    limit: 25,
    page: 1,
    sortBy: '',
    orderBy: 'desc'
}

let initialAllDeviceSelected = {
    isAllSelected: false,
    isAllClear: false,
    allSelected: false,
    totalSelectedDevice: 0
}


function OTAGroup(props) {
    const params = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const [refresh, setRefresh] = useState(false);
    const [accordion, setAccordian] = useState({ ...initialAccordion })
    const [newFilters, setNewFilters] = useState({ ...initialFilter })
    const [isDelete, setIsDelete] = useState(false)
    const [deletedDevice, setDeletedDevice] = useState([])
    const [allDevice, setAllDevice] = useState({ ...initialAllDeviceSelected })
    const [goToPage, setGoToPage] = useState('')

    const { singleOTAGroup = {}, isLoading } = useSelector(state => state.OTAGroupReducer)
    const delaySearch = useCallback(_.debounce(filters => refreshGroup(filters), 500), []);
    const delayGoToPagination = useCallback(_.debounce(filters => goToPageFun(filters), 1000), []);


    useEffect(() => {
        refreshGroup(newFilters)
        return () => {
            dispatch(actions.clearStoreForGroup())
        }
    }, [])

    const refreshGroup = (filters) => {
        if (params.id) {
            dispatch(actions.getGroupDetails(params.id, filters))
        }
    }

    const goToPageFun = (value) => {
        refreshGroup(value)
        // setNewFilters({ ...filters, page: parseInt(value) })
    }

    const onClickRefresh = () => {
        setRefresh(true)
        dispatch(actions.getGroupDetails(params.id, newFilters)).then(() => setRefresh(false)).catch(err => setRefresh(false))
    }

    const onPageChange = (e, page) => {
        if (allDevice?.allSelected) {
            setDeletedDevice([])
        }
        refreshGroup({ ...newFilters, page })
        setNewFilters({ ...newFilters, page })
        setAllDevice({ ...initialAllDeviceSelected })
    }

    const openAddGroupDialog = (item) => {
        dispatch(commonActions.openGlobalDialog({ getAllOTAGroupFn: () => { }, id: params.id, name: singleOTAGroup?.group_info?.name }, 'ADD_OTA_GROUP'))
    }

    const onChangeLimit = (e) => {
        setDeletedDevice([])
        setAllDevice({ ...initialAllDeviceSelected })
        refreshGroup({ ...newFilters, limit: e.target.value, page: 1, sortBy: 'created_at', orderBy: 'desc', })
        setNewFilters({ ...newFilters, limit: e.target.value, page: 1, })
        setGoToPage('')
    }

    const onSingleDeleteDevice = (e, item) => {
        let deviceIndex = deletedDevice.findIndex(res => res.device_id === item.device_id)
        let allDevices = [...deletedDevice]
        if (deviceIndex > -1) {
            allDevices.splice(deviceIndex, 1)
        } else {
            allDevices.push(item)
        }
        setDeletedDevice(allDevices)
    }

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {
            const cpSelected = [...deletedDevice, ...singleOTAGroup?.devices?.data]
            const ids = cpSelected.map(o => o.device_id)
            const filtered = cpSelected.filter(({ device_id }, index) => !ids.includes(device_id, index + 1))
            setDeletedDevice(filtered)
            if (!newFilters.serial_number.length) {
                setAllDevice({ ...allDevice, isAllSelected: true, totalSelectedDevice: singleOTAGroup?.devices?.data?.length })
            }
        } else {
            let tempSelected = [...deletedDevice]
            for (let i = 0; i < deletedDevice?.length; i++) {
                let matchedSelected = tempSelected.find(data => data.device_id === singleOTAGroup.devices?.data[i].device_id)
                let MatchedIndex = tempSelected.findIndex(data => data.device_id === singleOTAGroup.devices?.data[i].device_id)
                if (matchedSelected?.device_id) {
                    tempSelected.splice(MatchedIndex, 1)
                }
            }
            setDeletedDevice(tempSelected)
            setAllDevice({ ...allDevice, isAllSelected: false })
        }
    };

    const clearAllDevice = () => {
        setDeletedDevice([])
        setAllDevice({ ...initialAllDeviceSelected })
    }

    const selectAllDevice = () => {
        setAllDevice({ ...allDevice, isAllSelected: false, allSelected: true, totalSelectedDevice: singleOTAGroup?.devices?.total })
    }

    const applyDeleteDevice = () => {
        if (deletedDevice?.length) {
            const { allSelected = false } = allDevice
            const { id = '' } = params
            dispatch(actions.deleteDeviceFromGroup({ deletedDevice, allSelected, id })).then(res => {
                refreshGroup(newFilters)
                setDeletedDevice([])
                setAllDevice({ ...initialAllDeviceSelected })
                setIsDelete(false)
            })
        }
    }

    const onSortBy = (label) => {
        if (label === "available_firmwar" || label === "upgradable") return
        let orderBy = ''
        if (newFilters.orderBy === 'desc') {
            orderBy = 'asc'
        } else {
            orderBy = 'desc'
        }
        const tempFilters = { ...newFilters, sortBy: label, orderBy: orderBy }
        setNewFilters(tempFilters)
        setDeletedDevice([])
        setAllDevice({ ...initialAllDeviceSelected })
        refreshGroup(tempFilters)
        setIsDelete(false)
    }

    const openFilterSidebar = () => {
        setNewFilters({ ...newFilters, isOpen: true })
    }

    const onCloseFiltersDrawer = () => {
        setNewFilters({ ...newFilters, isOpen: false })
    }

    const handleChangeAccordion = (panel, filterKey) => (event, isExpanded) => {
        if (!isExpanded) {
            setNewFilters({ ...newFilters, [filterKey]: '', page: newFilters[filterKey]?.length ? 1 : newFilters.page })
        }
        setAccordian({ ...accordion, [panel]: isExpanded })
        if (newFilters[filterKey]?.length) {
            refreshGroup({ ...newFilters, [filterKey]: '', page: 1 })
        }
    };

    const onChangeFilter = ({ target: { name = '', value = '' } }) => {
        let tempFilter = { ...newFilters }
        tempFilter = { ...tempFilter, [name]: value, page: 1 }
        setNewFilters(tempFilter)
        setAllDevice({ ...initialAllDeviceSelected })
        setDeletedDevice([])
        // delaySearch({ ...tempFilter })
    }

    const onChangeGoToPage = ({ target: { value = '' } }) => {
        if (!value.match(/^[0-9]*$/) || value === "0") return
        let pageValue = Math.ceil(singleOTAGroup?.devices?.total / newFilters.limit)
        if (value > pageValue) return
        setGoToPage(value ? parseInt(value) : '')
        setNewFilters({ ...newFilters, page: value ? parseInt(value) : '' })
        delayGoToPagination({ ...newFilters, page: value })
    }

    const clearFilter = () => {
        // setClearFilterLoader(true)
        let tempFilter = {
            isOpen: newFilters.isOpen,
            serial_number: '',
            limit: 25,
            page: 1,
            sortBy: '',
            orderBy: 'desc'
        }

        setNewFilters({ ...tempFilter })
        setAccordian({ ...initialAccordion })
        // dispatch(actions.getAllOTAGroup(tempFilter)).then(res => {
        //     // setClearFilterLoader(false)  
        // })
        refreshGroup({ ...tempFilter })
    }

    const applyFilter = () => {
        refreshGroup({ ...newFilters, page: 1 })
    }

    const onChangeKeyDown = (e) => {
        if (e.key === 'Enter') {
            refreshGroup({ ...newFilters, page: 1 })
        }
    }

    const { t = () => { }, toast, classes } = props;

    let allDeviceonPage = singleOTAGroup.devices?.data
    let allSelectedFromTable = true
    let matchedAtleastOne = false
    for (let i = 0; i < allDeviceonPage?.length; i++) {
        const isExists = deletedDevice.find(item => item?.device_id === singleOTAGroup.devices?.data[i]?.device_id) || false
        if (isExists) {
            matchedAtleastOne = true
        }
        if (!isExists) {
            allSelectedFromTable = false
        }
    }

    let deviceType = getSelectedDeviceType()


    return (
        <div>
            <Grid container className="location-container">
                <Grid item xs={12} >
                    <Grid container className="location-head mb-4 con-space-between">
                        <div className='d-flex align-items-center'>
                            <div className="back-btn">
                                <div className="back-icon back-btn" onClick={() => history.goBack()}>
                                    <ArrowBackIosIcon />
                                </div>
                            </div>
                            <h2> Group Details {singleOTAGroup?.group_info?.name ? `[${singleOTAGroup.group_info.name}]` : null} </h2>
                        </div>

                        <div className='search-add'>

                            <Button
                                className="button-btn btn-custom-primary mr-3"
                                onClick={openAddGroupDialog}
                            >
                                <AddIcon />
                                Add Devices
                            </Button>
                            <button className="filter-btn-style" onClick={openFilterSidebar}><img src="/images/filter.svg" /></button>
                        </div>

                    </Grid>
                </Grid>
                <Grid container className="boxshadow-custom common-add-component location-container location-container-grey" style={{ marginTop: 20 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div >
                            <Grid container>
                                <Grid item sm={8} md={10}>
                                    {
                                        allDevice.isAllSelected && deletedDevice?.length ?
                                            <div className="custom-list-style mt-3 p-3 custom-line">
                                                All <strong className="mx-1"> {allDevice.totalSelectedDevice}</strong> devices on this page are selected.
                                                <div onClick={selectAllDevice} className="OTA-all-device-selected cursor_pointer  ml-1" style={{ color: '#00a88e' }}>
                                                    {deletedDevice?.length !== singleOTAGroup?.devices?.total ? `Select All ${singleOTAGroup?.devices?.total} Devices` : ''}
                                                </div>
                                            </div>
                                            : allDevice.allSelected && deletedDevice?.length ?
                                                <div className="custom-list-style mt-3 p-3 custom-line">
                                                    All <strong className="mx-1">{allDevice.totalSelectedDevice}</strong> devices are selected.
                                                    <div onClick={clearAllDevice} className="OTA-all-device-selected ml-1" style={{ color: '#00a88e' }}>
                                                        {
                                                            deletedDevice?.length !== singleOTAGroup?.devices?.total ? "Clear Selection" : ''
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                    }
                                </Grid>
                                <Grid item sm={4} md={2} className="d-flex align-items-center justify-content-right">
                                    {
                                        deletedDevice?.length ? <Button
                                            className="button-btn btn-custom-primary mr-3"
                                            onClick={applyDeleteDevice}
                                        >
                                            {deletedDevice?.length > 1 ? "Delete Devices" : "Delete Device"}
                                        </Button> : null
                                    }
                                </Grid>
                            </Grid>
                            <CustomDrawer isOpen={newFilters.isOpen} onClose={onCloseFiltersDrawer} >
                                <ul>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.serialNumberAcc}
                                            handleChange={handleChangeAccordion('serialNumberAcc', 'serial_number')}
                                            label={deviceType === "safelink" ? "MAC Address" : "Serial Number"} >
                                            <CustomSearchBar
                                                placeholder={deviceType === "safelink" ? "Enter MAC Address" : "Enter Serial Number"}
                                                value={newFilters.serial_number}
                                                onChange={onChangeFilter}
                                                name={'serial_number'}
                                                onChangeKeyDown={onChangeKeyDown}
                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <SearchFilterButtons
                                            clearFilter={clearFilter}
                                            applyFilter={applyFilter}
                                            isLoading={isLoading}
                                        // clearFilterLoader={clearFilterLoader}
                                        />
                                    </li>
                                </ul>
                            </CustomDrawer>
                        </div>
                    </Grid>

                    <Grid item xs={12}>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} className="content-container">
                        <div id="no-more-tables" className="card-design table-design cf" >

                            {
                                isLoading ?
                                    <>
                                        <TableContainer className={classes.container} style={{ overflow: "hidden" }}>
                                            <Table className="table-program" stickyHeader aria-label="sticky table">
                                                <EnhancedTableHead
                                                    headCells={deviceType === "safelink" ? manageSafelinkHead : manageHead}
                                                    numSelected={deletedDevice?.length || 0}
                                                    onSelectAllClick={handleSelectAllClick}
                                                    rowCount={deletedDevice.length}
                                                    checkbox={true}
                                                    isHeadChecked={allSelectedFromTable}
                                                    matchedAtleastOne={matchedAtleastOne}
                                                />
                                            </Table>
                                        </TableContainer>
                                        <TableLoader />
                                    </>
                                    : null
                            }

                            {
                                !isLoading ? <TableContainer className={classes.container}>
                                    <Table className="table-program" stickyHeader aria-label="sticky table">
                                        <EnhancedTableHead
                                            headCells={deviceType === "safelink" ? manageSafelinkHead : manageHead}
                                            numSelected={deletedDevice?.length || 0}
                                            onSelectAllClick={handleSelectAllClick}
                                            rowCount={deletedDevice.length}
                                            checkbox={true}
                                            isHeadChecked={allSelectedFromTable}
                                            matchedAtleastOne={matchedAtleastOne}
                                            onSortBy={onSortBy}
                                            filters={newFilters}
                                        />
                                        <TableBody>
                                            {
                                                singleOTAGroup.devices?.data?.length ? singleOTAGroup.devices?.data.map((item, index) => {
                                                    const isSelected = deletedDevice.find(selItem => selItem.id === item.id) || false
                                                    return <TableRow key={item?.id} hover role="checkbox" className="cursor_default">
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                // indeterminate={!!isSelected}
                                                                // checkedIcon={<DeleteForeverIcon size="medium" style={{ color: "red" }} />}
                                                                icon={<CheckBoxOutlineBlankIcon size="medium" />}
                                                                checked={!!isSelected}
                                                                onChange={(e) => onSingleDeleteDevice(e, item)}
                                                            />
                                                        </TableCell>
                                                        <TableCell className="table-custom-width">{item.serial_number || "-"}</TableCell>
                                                        <TableCell className="table-custom-width">{item.firmware_version || "-"}</TableCell>
                                                        <TableCell className="table-custom-width">{item.available_firmware || "-"}</TableCell>
                                                        <TableCell className="table-custom-width">
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={!!item.upgradable}
                                                                        // onChange={(e) => onChangeUpgradeCheckbox(e, item)}
                                                                        name="status"
                                                                        color="primary"
                                                                    />
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                })
                                                    :
                                                    <TableRow>
                                                        <TableCell style={{ border: "none" }} colSpan="4" className="table-no-data">No Data Found</TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer> : null
                            }

                            <CustomTablePagination
                                count={singleOTAGroup?.devices?.total}
                                refresh={refresh}
                                onClickRefresh={onClickRefresh}
                                filters={newFilters}
                                onPageChange={onPageChange}
                                onChangeLimit={onChangeLimit}
                                onChangeGoToPage={onChangeGoToPage}
                                goToPage={goToPage}
                            />
                        </div>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
}

export default withTranslation("translations")(withStyles(tablestyle)(OTAGroup));
