import { apiGet, apiPost, apiPut, apiDelete, getDeviceType } from '../../../utils';


export function getAllOTAAPI(filters = {}) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status", "upgradable"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/ota/devices?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)
}

export function updateDeviceFirmwareAPI(data) {
    let { selected = [], allFirmwareTargetVersion = '', allSelected = false, allUpgradeTarget = false } = data

    let allUpgradable = {}

    if (allSelected) {
        allUpgradable = {
            upgradable: allUpgradeTarget,
            available_firmware: allFirmwareTargetVersion
        }
    }

    selected = selected?.length ? selected.map(res => ({ ...res, upgradable: allUpgradeTarget })) : []
    selected = selected?.length ? selected.filter(item => item.available_firmware) : []

    let obj = {
        devices: allSelected ? [] : selected,
        all: allUpgradable,
        deviceType: getDeviceType()
    }
    return apiPut('/api/ota/update-firmware', obj)
}

export function singleDeviceUpdateFirmwareAPI(data) {
    let allUpgradable = {}
    let obj = {
        devices: data,
        all: allUpgradable,
        deviceType: getDeviceType()

    }
    return apiPut('/api/ota/update-firmware', obj)
}
