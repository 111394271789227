import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from '@material-ui/core/styles';
import EnhancedTableHead from "../../../components/common/EnhancedTableHead";
import CustomTablePagination from "../../../components/common/CustomPagination";
import { withTranslation } from "react-i18next";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { tablestyle, getComparator, stableSort } from '../../../utils'
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import EditIcon from '@material-ui/icons/Edit';
import TableLoader from "../../../components/common/TableLoader";
import DeleteIcon from '@material-ui/icons/Delete';
import CustomAddModal from '../../../components/common/CustomAddModal'
import validation from '../../Validation'
import * as commonActions from '../../common/actions'
import * as OTAAction from '../OTAs/actions'
import * as actions from './actions'
import Input from '../../../components/common/Input'
import qs from 'query-string'
import { useHistory, useLocation } from "react-router-dom";
import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Switch,
    FormControlLabel,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    FormGroup
} from '@material-ui/core';
import CustomDrawer from "../../../components/common/CustomDrawer";
import CustomAccordion from "../../../components/common/CustomAccordion";
import CustomSearchBar from "../../../components/common/CustomSearchBar";
import SearchFilterButtons from "../../../components/common/SearchFilterButtons";
import CusBackdrop from '../../../components/common/Backdrop'

const manageHead = [
    { id: 'name', sortByKey: 'name', label: "Name", className: "show-pointer" },
    { id: 'from_version', sortByKey: 'from_version', label: "Installed On", className: "show-pointer" },
    { id: 'to_version', sortByKey: 'to_version', label: "Installed To", className: "show-pointer" },
    { id: 'upgrade', sortByKey: 'upgrade', label: "Upgrade", className: "show-pointer" },
    { id: "action", sortByKey: 'action', label: "Actions", stickyClassName: "more" }
];

const initialRule = {
    to: '',
    from: '',
    upgrade: false,
    name: ''
}

const initialAccordion = {
    ruleNameAcc: false,
    fromVersionAcc: false,
    toVersionAcc: false
}

const initialFilter = {
    isOpen: false,
    name: '',
    from_version: '',
    to_version: '',
    limit: 25,
    page: 1,
    sortBy: '',
    orderBy: 'desc'
}

function Aggregates(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [refresh, setRefresh] = useState(false);
    const [rule, setRule] = useState({ ...initialRule })
    const [deleteRuleId, setdeleteRuleId] = useState('')
    const [randomValue, setRandomvalue] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [btnTitle, setBtnTitle] = useState('ADD')
    const [errors, setErrors] = useState({})
    const [editMode, setEditMode] = useState(false)
    const [selectedId, setSelectedId] = useState('')
    const [accordion, setAccordian] = useState({ ...initialAccordion })
    const [newFilters, setNewFilters] = useState({ ...initialFilter })
    const [goToPage, setGoToPage] = useState('')
    const [clearFilterLoader, setClearFilterLoader] = useState(false)


    const { allRule = {}, isLoading = false } = useSelector(state => state.ruleReducer)
    const { firmwareList = [] } = useSelector(state => state.OTA)
    const { data, total = 0 } = allRule

    const delaySearch = useCallback(_.debounce(filters => refreshRules(filters), 500), []);
    const delayGoToPagination = useCallback(_.debounce(filters => goToPageFun(filters), 1000), []);

    useEffect(() => {
        dispatch(OTAAction.getAllOTA({}))
        let tempFilter = {}
        if (location.search) {
            let data = { ...qs.parse(location.search, { arrayFormat: 'bracket', }) }
            let {
                ruleNameAcc = false,
                fromVersionAcc = false,
                toVersionAcc = false,
                isOpen = false,
                name = '',
                from_version = '',
                to_version = '',
                limit = 25,
                page = 1,
                sortBy = '',
                orderBy = 'desc'
            } = data

            tempFilter = {
                isOpen: isOpen === "false" ? false : true,
                name,
                from_version,
                to_version,
                limit: parseInt(limit),
                page: parseInt(page),
                sortBy,
                orderBy,
            }

            let tempAcc = {
                ruleNameAcc: ruleNameAcc === "false" ? false : true,
                fromVersionAcc: fromVersionAcc === "false" ? false : true,
                toVersionAcc: toVersionAcc === "false" ? false : true
            }

            setNewFilters(tempFilter)
            setAccordian(tempAcc)
        }
        refreshRules(tempFilter)
        return () => {
            dispatch(actions.clearStoreForRule())
        }
    }, [])


    useEffect(() => {
        let tempFilter = { ...accordion, ...newFilters }
        location.search = qs.stringify({ ...tempFilter })
        history.push({
            pathname: '/admin/rules',
            search: location.search
        })
    }, [accordion, newFilters])


    const refreshRules = (filters) => {
        dispatch(actions.getAllRule(filters))
    }

    const goToPageFun = (value) => {
        refreshRules(value)
        // setNewFilters({ ...filters, page: parseInt(value) })
    }

    const onClickRefresh = () => {
        setRefresh(true)
        dispatch(actions.getAllRule(newFilters)).then(() => setRefresh(false)).catch(err => setRefresh(false))
    }

    const onPageChange = (e, page) => {
        refreshRules({ ...newFilters, page })
        setNewFilters({ ...newFilters, page })
    }

    const isValid = () => {
        const { isValid, errors } = validation.addRule(rule)
        if (!isValid) {
            setErrors(errors)
        };
        return isValid;
    }

    const onSubmitModal = () => {
        if (isValid()) {
            let obj = {
                name: rule.name,
                from_version: rule.from,
                to_version: rule.to,
                immediate_update: rule.upgrade,
                // schedule_on: "2021-01-01 15:00:00"
            }
            if (editMode) {
                obj = {
                    ...obj,
                    id: selectedId
                }
                dispatch(actions.updateRule(obj)).then(res => {
                    refreshRules(newFilters)
                    handleModalClose()
                })
            } else {
                dispatch(actions.addNewRule(obj)).then(res => {
                    refreshRules(newFilters)
                    handleModalClose()
                })
            }
        }
    }

    const enableEditMode = (item) => {
        setSelectedId(item.id)
        setRule({ ...rule, to: item.to_version, from: item.from_version, upgrade: item.immediate_update, name: item.name })
        setOpenModal(true)
        setEditMode(true)
        setBtnTitle('Update')
    }

    const handleModalClose = () => {
        setSelectedId('')
        setRule({ ...initialRule })
        setOpenModal(false)
        setEditMode(false)
        // setBtnTitle('Add')
        setErrors({})
    }

    useEffect(() => {
        if (openModal === false)
            setTimeout(() => {
                setBtnTitle('Add')
            }, 150)
    }, [openModal])

    const onDeleteRetailer = (id) => {
        setdeleteRuleId(id)
        setRandomvalue(Math.random())
    }

    useEffect(() => {
        if (deleteRuleId) {
            dispatch(commonActions.openDeleteModal({
                confirmAction: deleteRule,
                title: 'DELETE RULE',
                dialogtext: 'Are you sure you want to delete this Rule?'
            }))
        }
    }, [randomValue])

    const deleteRule = () => {
        dispatch(actions.deleteRule(deleteRuleId)).then(res => {
            setNewFilters({ ...newFilters, limit: newFilters?.limit, page: 1, sortBy: 'created_at', orderBy: 'desc' })
            refreshRules({ ...newFilters, limit: newFilters?.limit, page: 1, sortBy: 'created_at', orderBy: 'desc' })
            setdeleteRuleId('')
        })
    }

    const onChangeLimit = (e) => {
        refreshRules({ ...newFilters, limit: e.target.value, page: 1, sortBy: 'created_at', orderBy: 'desc', })
        setNewFilters({ ...newFilters, limit: e.target.value, page: 1, })
        setGoToPage('')

    }

    const onSortBy = (label) => {
        if (label === "action") return
        let orderBy = ''
        if (newFilters.orderBy === 'desc') {
            orderBy = 'asc'
        } else {
            orderBy = 'desc'
        }
        const tempFilter = { ...newFilters, sortBy: label, orderBy: orderBy }
        setNewFilters(tempFilter)
        refreshRules(tempFilter)
    }

    // const clearFilter = () => {
    //     setNewFilters({ ...initialFilter })
    //     refreshRules(initialFilter)
    // }

    const handleChangeRule = (e, key) => {
        if (key === 'name' && e.target.value.length > 40) return
        if (key === "upgrade") {
            setRule({ ...rule, [key]: e.target.checked })
            return
        } else {
            setRule({ ...rule, [key]: e.target.value })
            setErrors({ ...errors, [key]: '' })
        }
    }

    const handleChangeEnableRule = (e, item) => {
        let obj = {
            name: item.name,
            from_version: item.from_version,
            to_version: item.to_version,
            immediate_update: e.target.checked,
            id: item.id
            // schedule_on: "2021-01-01 15:00:00"
        }
        dispatch(actions.updateRule(obj)).then(res => {
            refreshRules(newFilters)
        })
    }

    const onChangeGoToPage = ({ target: { value = '' } }) => {
        if (!value.match(/^[0-9]*$/) || value === "0") return
        let pageValue = Math.ceil(total / newFilters.limit)
        if (value > pageValue) return
        setGoToPage(value ? parseInt(value) : '')
        setNewFilters({ ...newFilters, page: value ? parseInt(value) : '' })
        // delayGoToPagination({ ...newFilters, page: value })
    }

    const openFilterSidebar = () => {
        setNewFilters({ ...newFilters, isOpen: true })
    }

    const onCloseFiltersDrawer = () => {
        setNewFilters({ ...newFilters, isOpen: false })
    }

    const handleChangeAccordion = (panel, filterKey) => (event, isExpanded) => {
        if (!isExpanded) {
            setNewFilters({ ...newFilters, [filterKey]: '', page: newFilters[filterKey]?.length ? 1 : newFilters.page })
        }
        setAccordian({ ...accordion, [panel]: isExpanded })
        // if (newFilters[filterKey]?.length) {
        //     refreshRules({ ...newFilters, [filterKey]: '', page: 1 })
        // }
    };

    const onChangeFilter = ({ target: { name = '', value = '' } }) => {
        let tempFilter = { ...newFilters }
        tempFilter = { ...tempFilter, [name]: value, page: 1 }
        setNewFilters(tempFilter)
        // delaySearch({ ...tempFilter })
    }

    const applyFilter = () => {
        refreshRules({ ...newFilters, page: 1 })
    }

    const clearFilter = () => {
        setClearFilterLoader(true)
        let tempFilter = {
            isOpen: newFilters.isOpen,
            serial_number: '',
            firmware_version: '',
            upgradable: [],
            limit: 25,
            page: 1,
            sortBy: '',
            orderBy: 'desc'
        }

        setNewFilters({ ...tempFilter })
        setAccordian({ ...initialAccordion })
        dispatch(actions.getAllRule(tempFilter)).then(res => {
            setClearFilterLoader(false)
        })
    }

    const onChangeKeyDown = (e) => {
        if (e.key === 'Enter') {
            refreshRules({ ...newFilters, page: 1 })
        }
    }

    const { t = () => { }, toast, classes } = props;
    let searchVersionList = firmwareList?.length ? firmwareList.map(res => ({ label: res.version || '', value: res.version || '' })) : []

    return (
        <Grid container className="location-container" >
            <Grid item xs={12} >
                <Grid container className="location-head">
                    <Grid item xs={12} sm={12} lg={6} md={4} className="heading">
                        <h2>Rules</h2>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} md={8}>
                        <div className="search-add">
                            <Button className="button-btn btn-custom-primary mr-3" onClick={() => setOpenModal(true)}><AddIcon />Add Rule</Button>
                            <button className="filter-btn-style" onClick={openFilterSidebar}><img src="/images/filter.svg" /></button>
                            <CustomDrawer isOpen={newFilters.isOpen} onClose={onCloseFiltersDrawer} >
                                <ul className="list-style-list">
                                    <CusBackdrop open={isLoading} className="loader-images" />
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.ruleNameAcc}
                                            handleChange={handleChangeAccordion('ruleNameAcc', 'name')}
                                            label={"Rule Name"}
                                        >
                                            <CustomSearchBar
                                                placeholder="Enter Rule Name"
                                                value={newFilters.name}
                                                onChange={onChangeFilter}
                                                name={'name'}
                                                onChangeKeyDown={onChangeKeyDown}

                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.fromVersionAcc}
                                            handleChange={handleChangeAccordion('fromVersionAcc', 'from_version')}
                                            label={"Installed On"}
                                        >
                                            <div className="select-custom">
                                                <FormControl variant="outlined" >
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                        Select
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={newFilters.from_version}
                                                        onChange={onChangeFilter}
                                                        name="from_version"
                                                        label="Select"
                                                    >
                                                        {
                                                            searchVersionList?.length ? searchVersionList.map((res, index) => {
                                                                return <MenuItem key={index} value={res.value}>{res.label}</MenuItem>
                                                            }) : []
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.toVersionAcc}
                                            handleChange={handleChangeAccordion('toVersionAcc', 'to_version')}
                                            label={"Installed To"}
                                        >
                                            <div className="select-custom">
                                                <FormControl variant="outlined" >
                                                    <InputLabel id="demo-simple-select-outlined-label">
                                                        Select
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={newFilters.to_version}
                                                        onChange={onChangeFilter}
                                                        name="to_version"
                                                        label="Select"
                                                    >
                                                        {
                                                            searchVersionList?.length ? searchVersionList.map((res, index) => {
                                                                return <MenuItem key={index} value={res.value}>{res.label}</MenuItem>
                                                            }) : []
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <SearchFilterButtons
                                            clearFilter={clearFilter}
                                            applyFilter={applyFilter}
                                            isLoading={isLoading}
                                            clearFilterLoader={clearFilterLoader}
                                        />
                                    </li>
                                </ul>
                            </CustomDrawer>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="content-container">
                <div id="no-more-tables" className="card-design table-design cf" >

                    {
                        isLoading ?
                            <>
                                <TableContainer className={classes.container} style={{ overflow: "hidden" }}>
                                    <Table className="table-program" stickyHeader aria-label="sticky table">
                                        <EnhancedTableHead
                                            headCells={manageHead}
                                        />
                                    </Table>
                                </TableContainer>
                                <TableLoader />
                            </>
                            : null
                    }

                    {
                        !isLoading ? <TableContainer className={classes.container}>
                            <Table className="table-program" stickyHeader aria-label="sticky table">
                                <EnhancedTableHead
                                    headCells={manageHead}
                                    onSortBy={onSortBy}
                                    filters={newFilters}
                                />
                                <TableBody>
                                    {
                                        data?.length ? data.map((item, index) => {
                                            return <TableRow key={index} hover role="checkbox" className="cursor_default">
                                                <TableCell className="table-custom-width">{item?.name || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{item?.from_version}</TableCell>
                                                <TableCell className="table-custom-width">{item?.to_version}</TableCell>
                                                <TableCell className="table-custom-width">
                                                    <FormControl component="fieldset">
                                                        <FormGroup aria-label="position" row>
                                                            <FormControlLabel
                                                                // value="top"
                                                                control={<Switch color="primary"
                                                                    checked={item.immediate_update}
                                                                    // disabled={true}
                                                                    onChange={(e) => handleChangeEnableRule(e, item)}
                                                                />
                                                                }
                                                                // label="Upgrade"
                                                                labelPlacement="end"
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell className="table-custom-width more" >
                                                    <EditIcon className="show-pointer" onClick={() => enableEditMode(item)} />
                                                    <DeleteIcon className="show-pointer" onClick={() => onDeleteRetailer(item.id)} />
                                                </TableCell>
                                            </TableRow>
                                        })
                                            :
                                            <TableRow>
                                                <TableCell style={{ border: "none" }} colSpan="5" className="table-no-data">No Data Found</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer> : null
                    }

                    <CustomTablePagination
                        count={total}
                        refresh={refresh}
                        onClickRefresh={onClickRefresh}
                        filters={newFilters}
                        onPageChange={onPageChange}
                        onChangeLimit={onChangeLimit}
                        onChangeGoToPage={onChangeGoToPage}
                        goToPage={goToPage}
                    />
                </div>
            </Grid>
            {isLoading ? null
                : <CustomAddModal
                    open={openModal}
                    createBtnTitle={btnTitle}
                    handleClose={handleModalClose}
                    createHandler={onSubmitModal}
                    closeHandler={handleModalClose}
                    title="Rule"
                >
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={12} xs={12} className="retailer-error" >
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel id="demo-simple-select-outlined-label" >Current Version *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={rule.from}
                                    onChange={(e) => handleChangeRule(e, "from")}
                                    label="Current Version *"

                                >
                                    {
                                        firmwareList && firmwareList.length ? firmwareList.map((item, index) => {
                                            return <MenuItem value={item.version} key={index}>{item.version}</MenuItem>
                                        }) : []
                                    }
                                </Select>
                                {errors.from && <span className="profile-error">{errors.from}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} className="retailer-error">
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel id="demo-simple-select-outlined-label" >Upgrade To *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={rule.to}
                                    onChange={(e) => handleChangeRule(e, "to")}
                                    label="Upgrade To *"

                                >
                                    {
                                        firmwareList && firmwareList.length ? firmwareList.map((item, index) => {
                                            return <MenuItem value={item.version} key={index}>{item.version}</MenuItem>
                                        }) : []
                                    }
                                </Select>
                                {errors.to && <span className="profile-error">{errors.to}</span>}
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} className="retailer-error">
                            <Input
                                variant="outlined"
                                fullWidth
                                name="name"
                                label="Name *"
                                type="text"
                                value={rule.name || ""}
                                onChange={(e) => handleChangeRule(e, "name")}
                            />
                            {errors.name && <span className="profile-error">{errors.name}</span>}
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} className="retailer-error">
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        // value="top"
                                        control={<Switch color="primary"
                                            checked={rule.upgrade}
                                            onChange={(e) => handleChangeRule(e, "upgrade")}
                                        />
                                        }
                                        label="Upgrade"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CustomAddModal>
            }
        </Grid >
    );

}

export default withTranslation("translations")(withStyles(tablestyle)(Aggregates));