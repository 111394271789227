import {
    getAllOTAAPI,
    updateDeviceFirmwareAPI,
    singleDeviceUpdateFirmwareAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_OTA_REQUEST = 'GET_ALL_OTA_REQUEST';
export const GET_ALL_OTA_SUCCESS = 'GET_ALL_OTA_SUCCESS';
export const GET_ALL_OTA_FAILED = 'GET_ALL_OTA_FAILED';

export const getAllOTA = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_OTA_REQUEST
        })
        getAllOTAAPI(filters).then(res => {
            const { info = {}, firmwares = [] } = res
            dispatch({
                type: GET_ALL_OTA_SUCCESS,
                payload: { info, firmwares }
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_OTA_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const UPDATE_DEVICE_FIRMWARE_REQUEST = 'UPDATE_DEVICE_FIRMWARE_REQUEST';
export const UPDATE_DEVICE_FIRMWARE_SUCCESSS = 'UPDATE_DEVICE_FIRMWARE_SUCCESSS';
export const UPDATE_DEVICE_FIRMWARE_FAILED = 'UPDATE_DEVICE_FIRMWARE_FAILED';

export const updateDeviceFirmware = (data) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_DEVICE_FIRMWARE_REQUEST
        })
        updateDeviceFirmwareAPI(data).then(res => {
            dispatch({
                type: UPDATE_DEVICE_FIRMWARE_SUCCESSS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_DEVICE_FIRMWARE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const SINGLE_DEVICE_UPDATE_FIRMWARE_REQUEST = "SINGLE_DEVICE_UPDATE_FIRMWARE_REQUEST"
export const SINGLE_DEVICE_UPDATE_FIRMWARE_SUCCESS = "SINGLE_DEVICE_UPDATE_FIRMWARE_SUCCESS"
export const SINGLE_DEVICE_UPDATE_FIRMWARE_FAILED = "SINGLE_DEVICE_UPDATE_FIRMWARE_FAILED"

export const singleDeviceUpdateFirmware = (data) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: SINGLE_DEVICE_UPDATE_FIRMWARE_REQUEST
        })
        singleDeviceUpdateFirmwareAPI(data).then(res => {
            dispatch({
                type: SINGLE_DEVICE_UPDATE_FIRMWARE_SUCCESS,
            })
            // dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: SINGLE_DEVICE_UPDATE_FIRMWARE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const CLEAR_STORE_FOR_OTA = "CLEAR_STORE_FOR_OTA"

export const clearStoreForOTA = () => dispatch => {
    return dispatch({
        type: CLEAR_STORE_FOR_OTA
    })
}