import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'
import {
    getAllSafelinkDevicesAPI,
    getSingleSafelinkDeviceAPI,
    getSmokeAlarmDeviceForTableAPI,
    getSafelinkDeviceHistoryByMacAddressAPI,
    singleSafelinkDeviceUpdateFirmwareAPI
} from './apis'

export const GET_ALL_SAFELINK_DEVIECS_REQUEST = 'GET_ALL_SAFELINK_DEVIECS_REQUEST';
export const GET_ALL_SAFELINK_DEVIECS_SUCCESS = 'GET_ALL_SAFELINK_DEVIECS_SUCCESS';
export const GET_ALL_SAFELINK_DEVIECS_FAILED = 'GET_ALL_SAFELINK_DEVIECS_FAILED';

export const getAllSafelinkDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_SAFELINK_DEVIECS_REQUEST
        })
        getAllSafelinkDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_SAFELINK_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_SAFELINK_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_SINGLE_SAFELINK_DEVIECS_REQUEST = 'GET_SINGLE_SAFELINK_DEVIECS_REQUEST';
export const GET_SINGLE_SAFELINK_DEVIECS_SUCCESS = 'GET_SINGLE_SAFELINK_DEVIECS_SUCCESS';
export const GET_SINGLE_SAFELINK_DEVIECS_FAILED = 'GET_SINGLE_SAFELINK_DEVIECS_FAILED';

export const getSingleSafelinkDevice = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SINGLE_SAFELINK_DEVIECS_REQUEST
        })
        getSingleSafelinkDeviceAPI(id).then(res => {
            dispatch({
                type: GET_SINGLE_SAFELINK_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SINGLE_SAFELINK_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_SMOKE_ALARM_TABLE_REQUEST = "GET_SMOKE_ALARM_TABLE_REQUEST";
export const GET_SMOKE_ALARM_TABLE_SUCCESS = "GET_SMOKE_ALARM_TABLE_SUCCESS";
export const GET_SMOKE_ALARM_TABLE_FAILED = "GET_SMOKE_ALARM_TABLE_FAILED";

export const getSmokeAlarmDeviceForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_SMOKE_ALARM_TABLE_REQUEST
        })
        getSmokeAlarmDeviceForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_SMOKE_ALARM_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SMOKE_ALARM_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_SAFELINK_DEVICE_HISTORY_REQUEST = 'GET_SAFELINK_DEVICE_HISTORY_REQUEST';
export const GET_SAFELINK_DEVICE_HISTORY_SUCCESS = 'GET_SAFELINK_DEVICE_HISTORY_SUCCESS';
export const GET_SAFELINK_DEVICE_HISTORY_FAILED = 'GET_SAFELINK_DEVICE_HISTORY_FAILED';

export const getSafelinkDeviceHistoryByMacAddress = (macAddress) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SAFELINK_DEVICE_HISTORY_REQUEST
        })
        getSafelinkDeviceHistoryByMacAddressAPI(macAddress).then(res => {
            const { info = [] } = res || {}
            dispatch({
                type: GET_SAFELINK_DEVICE_HISTORY_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_SAFELINK_DEVICE_HISTORY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const SINGLE_SAFELINK_DEVICE_UPDATE_FIRMWARE_REQUEST = "SINGLE_SAFELINK_DEVICE_UPDATE_FIRMWARE_REQUEST"
export const SINGLE_SAFELINK_DEVICE_UPDATE_FIRMWARE_SUCCESS = "SINGLE_SAFELINK_DEVICE_UPDATE_FIRMWARE_SUCCESS"
export const SINGLE_SAFELINK_DEVICE_UPDATE_FIRMWARE_FAILED = "SINGLE_SAFELINK_DEVICE_UPDATE_FIRMWARE_FAILED"

export const singleDeviceUpdateFirmware = (obj) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: SINGLE_SAFELINK_DEVICE_UPDATE_FIRMWARE_REQUEST
        })
        singleSafelinkDeviceUpdateFirmwareAPI(obj).then(res => {
            dispatch({
                type: SINGLE_SAFELINK_DEVICE_UPDATE_FIRMWARE_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: SINGLE_SAFELINK_DEVICE_UPDATE_FIRMWARE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const CLEAR_ALL_SAFELINK_DEVICE_DATA = 'CLEAR_ALL_SAFELINK_DEVICE_DATA';

export const clearStoreForSafelinkDevice = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_SAFELINK_DEVICE_DATA
        })
    }
}