import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox
} from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

function EnhancedTableHead(props) {
  const {
    headCells,
    checkbox = false,
    onSelectAllClick = () => { },
    numSelected = 0,
    isHeadChecked = false,
    matchedAtleastOne = false,
    onSortBy = () => { },
    filters = {}
  } = props;

  const { sortBy = '', orderBy = '' } = filters || {}

  return (
    <TableHead>
      <TableRow>
        {
          checkbox ? <TableCell padding="checkbox">
            <Checkbox
              indeterminate={(numSelected > 0 && matchedAtleastOne && !isHeadChecked) || false}
              checked={(numSelected && isHeadChecked) || false}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell> : null
        }

        {
          headCells.map((headCell) => {
            return <TableCell key={headCell.id} className={headCell.stickyClassName}>
              <span className={headCell.className} onClick={() => onSortBy(headCell.sortByKey)}>
                {headCell.label}
                {sortBy === headCell.sortByKey ? orderBy === "asc" ? <ArrowUpwardIcon className="icon" /> : <ArrowDownwardIcon className="icon" /> : null}
              </span>
            </TableCell>
          })
        }
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;